<script>
import { transactionService } from "../../../helpers/backend/transaction.service";
import { customerService } from "../../../helpers/backend/customer.service";
import itemTemplate from "./item-template.vue";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      listData: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "ID", sortable: true, label: "ID" },
        {
          key: "customer.vehicle_number",
          sortable: true,
          label: "Vehicle Number",
        },
        { key: "customer.name", sortable: true, label: "Customer Name" },
        { key: "user.name", sortable: true, label: "Cashier Name" },
        { key: "worker.worker_name", sortable: true, label: "Worker Name" },
        { key: "CreatedAt", sortable: true, label: "Date" },
        { key: "action" },
      ],
      showDismissibleAlert: false,
      customer: {
        vehicle_number: "",
        customer_name: "",
        phone_number: "",
        vehicle_type: "",
        customer_id: null,
      },
      customerItems: [],
      itemTemplate,
      busy: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listData.length;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.listData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      this.error = null;
      this.loading = true;
      this.showDismissibleAlert = false;
      //const fetchedId = this.$route.params.id
      this.busy = true;
      transactionService.getAll(this.customer.customer_id).then(
        (data) => {
          this.listData = data.transactions;
          this.loading = false;
          this.busy = false;
        },
        (err) => {
          this.error =
            "Failed to fetch customer data. Error : " +
            err +
            "  Dissmiss to refresh";
          this.loading = false;
          this.showDismissibleAlert = true;
          this.busy = false;
        }
      );
    },
    onDelete(id) {
      //console.log(id);
      if (
        confirm(
          "Do you really want to delete this instance, this action can't be undone?"
        )
      ) {
        transactionService.deleteById(id).then(
          (data) => {
            this.fetchData();
            console.log(data);
          },
          (err) => {
            this.error = "Failed to delete transaction. Error : " + err;
            this.showDismissibleAlert = true;
          }
        );
      }
    },
    onUpdate(id) {
      this.$emit("onEdit", id);
    },
    onShow(id) {
      this.$emit("onShow", id);
    },
    // Autocomplete for customer
    inputCustomerChange(text) {
      if (text.length > 1) {
        customerService.getAll(text).then((data) => {
          this.customerItems = data.customers.map(function (cust) {
            return {
              customer_id: cust.ID,
              customer_name: cust.name,
              vehicle_type: cust.vehicle_type.vehicle_name,
              vehicle_number: cust.vehicle_number,
              phone_number: cust.phone_number,
            };
          });
        });
      }
    },
    itemCustomerSelected(item) {
      const itemClone = JSON.stringify(item);
      this.customer = JSON.parse(itemClone);
      this.fetchData();
    },
    setCustomerLabel(item) {
      return item.vehicle_number;
    },
    resetCustomer(event) {
      console.log(event);
      if (event.pointerId == 0) return;
      for (var key in this.customer) {
        this.customer[key] = "";
      }
      this.fetchData();
    },
  },
};
</script>
<style scope>
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid #023d7b;
  position: relative;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  padding: 10px;
  border-left: 1px solid #023d7b;
  border-right: 1px solid #023d7b;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}
.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}
.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}
.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}
</style>
<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <div class="card">
        <div class="card-body">
          <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

          <h4 class="card-title mb-4">Customer</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Customer Filter :&nbsp;
                  <div v-if="customer.customer_id" style="margin-left: 20px">
                    <div
                      class="badge font-size-12 badge-success"
                      style="float: left"
                    >
                      {{ customer.vehicle_number }}
                    </div>
                    <b-btn-close
                      class="font-weight-bold"
                      style="color: red; padding-left: 20px"
                      @click="resetCustomer"
                    />
                  </div>
                  <template v-else>
                    <vue-suggestion
                      style="margin-left: 20px"
                      :items="customerItems"
                      @changed="inputCustomerChange"
                      :setLabel="setCustomerLabel"
                      @selected="itemCustomerSelected"
                      :itemTemplate="itemTemplate"
                    >
                    </vue-suggestion>
                  </template>
                </label>
              </div>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-md-4">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              :items="listData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(CreatedAt)="row">
                <div>{{ new Date(row.value).toDateString() }}</div>
              </template>

              <template v-slot:cell(action)="row">
                <a
                  @click="onUpdate(row.item.ID)"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  @click="onShow(row.item.ID)"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Show"
                >
                  <i class="mdi mdi-eye font-size-18"></i>
                </a>
                <a
                  @click="onDelete(row.item.ID)"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>