<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import List from "./list";
import PrintPreview from "../dashboard/transaction-component/print-preview";
import FormTransaction from "../dashboard/transaction-component/form-transaction.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    PrintPreview,
    FormTransaction,
  },
  data() {
    return {
      title: "Transaction",
      showModal: false,
      items: [
        {
          text: "Agenda",
        },
        {
          text: "Transaction",
          active: true,
        },
      ],
    };
  },
  methods: {
    onRefresh() {
      this.$refs.list.fetchData();
    },
    onEdit(id) {
      console.log("receive emit")
      this.$refs.form_transaction.initUpdate(id);
    },
    onShow(id) {
      this.showModal = true;
      window.setTimeout(() => {
        this.$refs.printPreview.fetchData(id);
      }, 500);
    },
    confirmPrint(event) {
      event.preventDefault();
      this.showModal = !this.$refs.printPreview.print();
    },
    cancelPrint(event) {
      event.preventDefault();
      this.showModal = !this.$refs.printPreview.cancel();
    },
    onReset() {
      this.$refs.list.fetchData();
    }
  },
};
</script>

<template>
  <Layout>
    <b-modal
      v-model="showModal"
      @ok="confirmPrint"
      @cancel="cancelPrint"
      ok-title="Print"
      size="xl"
      id="my-modal"
    >
      <PrintPreview ref="printPreview" />
    </b-modal>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <!-- <Form ref="form" @refresh="onRefresh" /> -->
        <FormTransaction isUpdateMode="true" ref="form_transaction" style="padding : 10px" @onReset="onReset"/>
        <List ref="list" @onEdit="onEdit" @onShow="onShow" />
      </div>
    </div>
  </Layout>
</template>